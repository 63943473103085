<template>
  <div class="Connect">
    <div class="title">{{ $t("nav.contact_us") }}</div>
    <div v-if="!success" class="main">
      <div class="tip">{{ $t("contactUs.tip2") }}</div>
      <div class="form">
        <el-form v-if="$store.state.platform == 'pc'" ref="form" :model="form">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('contactUs.title')">
                <el-input v-model="form.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('contactUs.email')">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('contactUs.phone')">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('contactUs.nickname')">
                <el-input v-model="form.nickname"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item :label="$t('contactUs.content')">
                <el-input type="textarea" resize="none" v-model="form.content"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <el-form v-else ref="form" :model="form">
          <el-form-item :label="$t('tip.firstName')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tip.secondName')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rigister.email')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('address.city')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('address.phone')">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('address.leave')">
            <el-input type="textarea" resize="none" v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
        <div class="submit" @click="submit">{{ $t("contactUs.submit") }}</div>
      </div>
    </div>
    <div v-else class="done">
      {{ $t("contactUs.success")
      }}<span @click="$router.go(-1)">{{ $t("contactUs.continue") }}</span>
    </div>
  </div>
</template>

<script>
import Api from "../../api/request.js";
export default {
  name: "Connect",
  components: {},
  data() {
    return {
      success: false,
      form: {
        email: "",
        phone: "",
        title: "",
        nickname: "",
        content: "",
      },
    };
  },
  created() {},
  methods: {
    submit() {
      this.$dialog.loading();
      Api.AboutUs.connect(this.form).then((res) => {
        if (res.data.status == "SUCCESSS") {
          this.success = true;
          this.$dialog.close();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  margin: 40px 0;
  text-align: center;
}
.main {
  padding: 0 62px 120px;
  .tip {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .form {
    padding: 20px 40px 40px;
    border: 1px solid #d9d9d9;
    .el-form {
      width: 900px;
      .el-row {
        margin-top: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      /deep/.el-form-item__label {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin: 0 0 12px;
      }
      /deep/.el-input__inner {
        border-radius: 0;
        border-color: #757575;
      }
      /deep/.el-textarea__inner {
        border-radius: 0;
        border-color: #757575;
        height: 208px;
      }
      /deep/.el-select .el-input .el-select__caret {
        color: #000;
        font-size: 12px;
        font-weight: bolder;
      }
    }
  }
  .submit {
    width: 200px;
    height: 48px;
    background: #123178;
    border-radius: 6px;
    text-align: center;
    line-height: 48px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin-top: 40px;
    &:hover {
      background-color: #021450;
    }
  }
}
.done {
  text-align: center;
  font-size: 15px;
  padding-bottom: 140px;
  & > span {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      font-weight: bolder;
    }
  }
}
@media screen and (max-width: 500px) {
  .title {
    font-size: 6vw;
    font-weight: 600;
    line-height: 6vw;
    margin: 4vw 0;
    text-align: center;
  }
  .main {
    padding: 0 3vw 6vw;
    .form {
      padding: 0 3vw 20px;
      .el-form {
        width: 100%;
        .el-form-item {
          margin-top: 20px;
          .el-select {
            width: 100%;
          }
        }
      }
    }
    .submit {
      margin-top: 20px;
    }
  }
}
</style>
